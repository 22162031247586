// import Swiper JS
import $ from 'jquery';
import mask from '../../node_modules/jquery.maskedinput/src/jquery.maskedinput';

import Swiper, { Navigation } from 'swiper';
Swiper.use([Navigation]);
import 'swiper/swiper.min.css';

const swiper = new Swiper('.swiper', {
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
    disabledClass: 'swiper__btn-disabled',
  },
  spaceBetween: 15,
  loop: true,
  breakpoints: {
    767: {
      slidesPerView: 2,
      spaceBetween: 25,
    },
    992: {
      slidesPerView: 3,
      spaceBetween: 25,
    },
    1200: {
      slidesPerView: 4,
    },
  },
});

// accardions
let acc = document.getElementsByClassName('accardion');

for (let i = 0; i < acc.length; i++) {
  acc[i].addEventListener('click', function () {
    this.classList.toggle('active');
  });
}

$('.phone-mask').mask('+38(999)999-99-99');

$('.header__menu-btn').click(function () {
  $(this).toggleClass('open');
  $('.menu').toggleClass('active');
});

$('.menu a').click(function () {
  $('.header__menu-btn').removeClass('open');
  $('.menu').removeClass('active');
});
